<div class="container">
    <div class="login-container">
        <div>
            <img src="assets\images\Mira_AI-colour_sm.png" class="responsive" />
        </div>
        <div class="login-card">
            <mat-card appearance="outlined" class="mira">
                <mat-card-content>
                    <button class="btn" (click)="oktaLogin()" class="mira-alternate-button">Login</button>
                </mat-card-content>
                <button class="btn" (click)="newAccount()">No account? Sign up!</button>
                <br /><br />
                <a href="https://www.teledynecaris.com/en/products/caris-mira-ai/">What is CARIS Mira AI?</a>
            </mat-card>
        </div>
    </div>
</div>
