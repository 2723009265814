import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import UrlSources from '../url-sources';
import { ClassifierService } from '../model/classifierservice';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class ClassifiersService {
    private classifiers: ClassifierService[] = [];
      
    constructor(private http: HttpClient) {}

    getServiceStatus(): Observable<any> {
        return this.http.get(UrlSources.get_classifier_status());
    }

    addService(name: string, status: number): void {
        const new_cl: ClassifierService = { Name: name, ProperName: this.translator(name), Status: status};
        this.classifiers.push(new_cl);
    }

    getStatus(name: string): boolean {
        let status = false;
        for (const svc of this.classifiers) {
            if (svc.Name === name) {
                if (svc.Status == 200) {
                    status = true;
                } else {
                    status = false;
                }
            }
        }
        return status;
    }

    translator(name: string): string {
        if (name.toLowerCase().includes("gnc")) {
            return "Galaxy Noise Classifier";
        } else if (name.toLowerCase().includes("sinc")) {
            return "Sonar Noise Classifier";
        }
    }
  }