import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class AuthGuard  {

  constructor(
    private router: Router, 
    @Inject(OKTA_AUTH)
    public oktaAuth: OktaAuth) {}

  canActivate(): Promise<boolean> {

    const getOktaState = async () => {
      const isAuthenticated = await this.oktaAuth.isAuthenticated();
      return isAuthenticated;
    };
    const oktaState = getOktaState();

    oktaState.then((isAuthenticated) => {
      if (!isAuthenticated) {
        this.router.navigate(['/login']);
      }
    });
    return oktaState;
  }

}