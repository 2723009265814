import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import UrlSources from '../url-sources';
import { User } from '../model/user';
import { Observable, Subject } from 'rxjs';
import * as jwt from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private user: User;
    private sessionUser: Subject<User> = new Subject();
    public users = new Subject<User[]>();
    public searchOption = [];
    public userData: User[];
    public login_name: string;
    showInactiveUsers = false;
    constructor(private http: HttpClient) { }

  getLoginName(): string {
      return this.login_name;
  }

  setLoginName(name: string): void {
      this.login_name = name;
  }

  getUserGroup(idToken): any {
      const decoded = jwt(idToken);
      return decoded['cognito:groups'][0];
  }

  isAdmin(accessToken): boolean {
    const decoded = jwt(accessToken);
    const groups = decoded['mira_groups'];
    return groups.find(group => group == "Mira AI Admin")
  }

  getUsername(): string {
    return this.user.email;
  }

  getCompanyId(): string {
    return "NONE";
  }

  getExpiryDate(): any {
    const expiry = this.user.expiry_date;

    return expiry === undefined ? null : expiry.valueOf;
  }

  getGAMExpiryDate(): any {
    const expiry = this.user.ukhogam_expiry_date;

    return expiry === undefined ? null : expiry.valueOf;
  }

  getUserInfo(accessToken): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      })
    };
    
    return this.http.get(UrlSources.get_user_info(), httpOptions );
  }

  applyRedeemCode(email: string, activation: string, accessToken: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      })
    };

    const postD = {
      email: email,
      redeem_code: activation,
    }
    return this.http.post(UrlSources.apply_redeem_code(), postD, httpOptions )
  }

  getProductItems(eId: string, accessToken: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      })
    };

    return this.http.get(UrlSources.get_product_items(eId), httpOptions);
  }

  postActivateProduct(eId: string, accessToken: string, productId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      })
    };

    const postD = {
        eid: eId,
        product_key: productId
    };
    return this.http.post(UrlSources.activate_product(), postD, httpOptions);
  }

    getFreeTrial(email: string, service: string, accessToken: string): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        };

        const postD = {
            email: email,
            service: service,
        }
        return this.http.post(UrlSources.free_trial(), postD, httpOptions )
    }

    forgetPassword(username: string): Observable<any> {
        const postD = {
            email: username
        };

        return this.http.post(UrlSources.forgetPassword(), postD, { headers: { 'Content-Type': 'application/json' } });
    }

    verifyPassword(username: string, confirmation: string, password: string): Observable<any> {
        const postD = {
            email: username,
            confirmation_code: confirmation,
            password: password
        };

        return this.http.post(UrlSources.confirm_password(), postD, { headers: { 'Content-Type': 'application/json' } });
    }


    change_Password(oldPass: string, newPass: string, accessToken: string, idToken: string): Observable<any> {
        const postD = {
            current_password: oldPass,
            new_password: newPass,
        }

        return this.http.post(UrlSources.changePassword(), postD, { headers: { 'access_token': accessToken, 'identity_token': idToken } });
    }


    forceChangePassword(username: string, newPassword: string, session: string): Observable<any> {
        const postD = {
            email: username,
            password: newPassword
        }

        return this.http.post(UrlSources.reset_password(), postD, { headers: { 'session': session } });
    }

    enable_User(username: string, idToken: string): Observable<any> {
        const postD = {
            email: username
        };
        return this.http.post(UrlSources.enable_user(), postD, { headers: { 'identity_token': idToken } });
    }

    disable_User(username: string, idToken: string): Observable<any> {
        const postD = {
            email: username
        };
        return this.http.post(UrlSources.disable_user(), postD, { headers: { 'identity_token': idToken } });
    }

    reset_User(username: string, idToken: string): Observable<any> {
        const postD = {
            email: username
        };
        return this.http.post(UrlSources.reset_user(), postD, { headers: { 'identity_token': idToken } });
    }

    deleteUser(username: string, idToken: string): Observable<any> {
        const postD = {
            email: username
        };
        return this.http.post(UrlSources.delete_user(), postD, { headers: { 'identity_token': idToken } });
    }

  updateLicenceExpiry(accessToken: string, username: string, expiry: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      })
    };

    const postD = {
      email: username,
      attribute: 'expiry_date',
      value: expiry
    };
    return this.http.post(UrlSources.update_user_attributes(), postD, httpOptions);

    }

  updateGAMLicenceExpiry(accessToken: string, username: string, expiry: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      })
    };

    const postD = {
      email: username,
      attribute: 'ukhogam_expiry_date',
      value: expiry
    };
    return this.http.post(UrlSources.update_user_attributes(), postD, httpOptions);

    }

    updateCompanyID(idToken: string, username: string, cid: string): Observable<any> {
        const postD = {
            email: username,
            attribute: 'company_id',
            value: cid,
        };
        return this.http.post(UrlSources.update_user_attributes(), postD, { headers: { 'identity_token': idToken } });
    }

    getSessionUser(): Subject<User> {
        return this.sessionUser;
    }

    setUser(user: User): void {
        this.user = user;
        this.sessionUser.next(this.user);
    }

    getUser(): User {
        return this.user;
    }

    includeInactiveUsers(includeInactiveUsers: boolean): void {
        this.showInactiveUsers = includeInactiveUsers;
    }

    getSearchOption(): any[] {
        return this.searchOption;
    }

    setSearchOption(search: any[]): void {
        this.searchOption = search;
    }

    addSearchOption(option: any): void {
        this.searchOption.push(option);
    }

    removeSearchOption(option: any): any {
        const index = this.searchOption.indexOf(option);
        if (index >= 0) this.searchOption.splice(index, 1);
    }


  getUserData(): User[] {
    // if (!this.showInactiveUsers) {
    //   return this.userData.filter(user => { return user.Enabled == true });
    // }
    return this.userData;
  }

    setUserData(users: User[]): void {
        // users = users.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        this.userData = users;
    }

  getUsers(accessToken: string): Observable<User[]> {

    const httpOptions = {
      headers: new HttpHeaders({
  
        'Authorization': 'Bearer ' + accessToken
      })
    };

    return this.http.get<User[]>(UrlSources.get_list_users(), httpOptions);
  }

    register(mail: string, company_id: string): Observable<any> {
        const postData = {
            email: mail,
            company_id: company_id,
        };

        return this.http.post(UrlSources.signup_users(), postData, { headers: { 'Content-Type': 'application/json' } });
    }

    createUser(newUser: any, idToken): Observable<any> {
        return this.http.post(UrlSources.create_users(), newUser, { headers: { 'identity_token': idToken } });
    }

    updateSearch(users: User[]): void {
        return this.users.next(users);
    }

  filteredListOptions(): User[] {
    const users = this.getUserData();
    const filteredUsersList = [];
    for (const user of users) {
      for (const options of this.searchOption) {
        if (options.username === user.username) {
          filteredUsersList.push(user);
        }
      }
    }
    return filteredUsersList;
  }

    getClassifierServiceStatus(): Observable<any> {
        return this.http.get(UrlSources.get_classifier_status());
    }
}
