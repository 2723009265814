import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/model/user';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { EditUserComponent } from './edit-users/edit-users.component';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { OktaAuthStateService } from '@okta/okta-angular';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css'],
  providers: [DatePipe],
})
export class ManageUsersComponent implements OnInit {

  title = 'users';
  allUsers: User[] = [];
  accessToken;
  caris_count = 0;
  // filteredUsers: User[] = [];

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['username', 'expiryDate', 'GAMExpiryDate', 'actions'];
  dataSource = new MatTableDataSource(this.allUsers);

  constructor(private _authService: AuthService, private usersService: UserService, private router: Router, public dialog: MatDialog,
    private location: Location, private toastr: ToastrService, private datepipe: DatePipe, private cd: ChangeDetectorRef, private _oktaStateService: OktaAuthStateService) {
  }

  ngOnInit(): void {
    this._oktaStateService.authState$.subscribe((state) => {

      if (state.isAuthenticated) {
        this.accessToken = state.accessToken.accessToken;
        this.getUsers();
      } else {
        this.router.navigateByUrl('/login');
      }
    });
    
  }

  getUsers(): void {
    const sortUsers = (a, b) => {
      const first = a.email.toLowerCase();
      const second = b.email.toLowerCase();
      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    }

    this.usersService.getUsers(this.accessToken).subscribe(users => {
      if(users['data'].length > 0) {
        this.allUsers = users['data'];
        this.allUsers.sort(sortUsers);
      }
    });
  }

  logoutuser(): void {
    this._authService.logout();
    this.router.navigateByUrl('/login');
  }

  filterTable(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editUser(user): void {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '40vw',
      height: '45vh',
      panelClass: 'custom-dialog',
      data: {
        dataKey: user,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const email = result.username;
        const new_exp = result.expiry;
        const new_gam_exp = result.GAMExpiry;

        const old_exp = moment(user.expiry_date).toDate();
        const old_gam_exp = moment(user.ukhogam_expiry_date).toDate();

        const observables: Array<Observable<object>> = []

        if (new_exp) {
            if (!moment(user.expiry_date).isValid() || new_exp.toDateString() !== old_exp.toDateString()) {
                const formatExpiry = this.datepipe.transform(new_exp, 'yyyyMMdd');
                observables.push(this.usersService.updateLicenceExpiry(this.accessToken, email, formatExpiry));
            }
        }

        if (new_gam_exp) {
            if (!moment(user.ukhogam_expiry_date).isValid() || new_gam_exp.toDateString() !== old_gam_exp.toDateString()) {
                const formatExpiry = this.datepipe.transform(new_gam_exp, 'yyyyMMdd');
                observables.push(this.usersService.updateGAMLicenceExpiry(this.accessToken, email, formatExpiry));
            }
        }

        forkJoin(observables).subscribe(success => {
          this.getUsers();
          this.table.renderRows();
          this.toastr.success('User updated.', '', { disableTimeOut: true });
          
        }, error => {
          this.toastr.error(error.error.message);
        });
      }
    });
  }

  goToProfile(): void {
    this.router.navigateByUrl('/user-page');
  }

  /** Formats an expiration date for display.
   *
   * @param expiration
   */
  formatExpiration(expiration: any): string {
    return expiration != null ? moment(expiration).format('YYYY/MM/DD') : "Unlicensed"
  }

}
