<div class="main-container">
    <mat-toolbar color="primary">
        <mat-toolbar-row class="modal-toolbar-row">
            <mat-icon>shop</mat-icon>
            <h5 mat-dialog-title>Select Product</h5>
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="modal-container">
        <div class="text">Please select the products you wish to activate</div>
        <div class="form">
            <div *ngFor="let product of products; let i = index" style="width: 100%">
                <mat-checkbox appearance="outline" class="checkbox" [value]="product" (change)="onCheck($event)">
                    {{product.value}}
                </mat-checkbox>
            </div>
            <button [disabled]="disableSubmit()" class="mira-alternate-button" style="min-width: 200px;" (click)="onSubmit()">Activate</button>
        </div>
    </div>
</div>