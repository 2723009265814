import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from 'src/app/authguard/authgaurd';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import appConfig from './app.config';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { CreateUserComponent } from './manage-users/create-users/create-users.component';
import { EditUserComponent } from './manage-users/edit-users/edit-users.component';
import { ActivatePurchaseComponent } from '../user-page/activate-purchase/activate-purchase.component';
import { SelectProductComponent } from '../user-page/select-product/select-product.component';
import { UserPageComponent } from '../user-page/user-page.component';

import { OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth(appConfig.oidc);
const moduleConfig: OktaConfig = { oktaAuth };

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ManageUsersComponent,
        CreateUserComponent,
        EditUserComponent,
        ActivatePurchaseComponent,
        SelectProductComponent,
        UserPageComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        ToastrModule.forRoot({
            timeOut: 2500,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        OktaAuthModule.forRoot(moduleConfig)
    ],
    providers: [CookieService, AuthGuard, ToastrService],
    bootstrap: [AppComponent]
})
export class AppModule { }
