import { Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

export interface DialogResult {
  username: string;
  companyid: string;
}

@Component({
    selector: 'app-create-user',
    templateUrl: 'create-users.component.html',
    styleUrls: ['./create-users.component.css']
})
export class CreateUserComponent {
    profileForm: UntypedFormGroup;
    private email: string;
    private company_id: string;

    constructor(public dialogRef: MatDialogRef<CreateUserComponent>, private formBuilder: UntypedFormBuilder) { 
      this.profileForm = this.formBuilder.group({
        email : ['', [Validators.required, Validators.email]],
        company_id: ['', [Validators.required]],
      },);
    }
    
    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
      this.email = this.profileForm.get('email').value;
      this.company_id = this.profileForm.get('company_id').value;
      
      const result: DialogResult = {
        username:this.email,
        companyid:this.company_id
      };

      this.dialogRef.close(
        result
      );
    
    }  
}