import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { ClassifiersService } from 'src/app/services/classifiers.service';
import { User } from 'src/app/model/user';
import { Router } from '@angular/router';
import { ActivatePurchaseComponent } from './activate-purchase/activate-purchase.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';
import { SelectProductComponent } from './select-product/select-product.component';
import { OktaAuthStateService } from '@okta/okta-angular';

@Component({
    selector: 'app-user-page',
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.css']
})
export class UserPageComponent implements OnInit {
    private user: User;
    private idToken;
    private aToken;
    private eId;
    public isAdmin: boolean
    public SNCexpiry: any;
    public GNCexpiry: any;
    public ukhoGAMExpiry: any;
    public username: string;
    public companyid: string;
    public SNCexpired: boolean;
    public ukhoGAMExpired: boolean;
    public SNCStatus = false;
    public GNCStatus = false;

    constructor(
        private router: Router,
        private _authService: AuthService, 
        private userService: UserService, 
        private classifiersService: ClassifiersService, 
        private dialog: MatDialog,
        private toastr: ToastrService,
        private _oktaStateService: OktaAuthStateService) { }

  ngOnInit(): void {

    this._oktaStateService.authState$.subscribe((state) => {
      if(state.isAuthenticated) {

        this.getClassifierServiceStatus();

        this.idToken = state.idToken.idToken;
        this.aToken = state.accessToken.accessToken;

        this.username = state.accessToken.claims.sub;
        this.getUserInfo();

        this.isAdmin = this.userService.isAdmin(this.aToken);

      } else {
        this.router.navigateByUrl('/login');
      }

    });
  }

  redeem(): void {
    const dialogRef = this.dialog.open(ActivatePurchaseComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'custom-dialog',
    });

        dialogRef.afterClosed().subscribe(result => {
            if (result) { 
                this.userService.getProductItems(result.eId, this.aToken).subscribe(succ => {
                    this.eId = result.eId;
                    this.activateProducts(succ);
                },
                err => {
                    this.toastr.error(err.error.message, '', { disableTimeOut: true });
                });
            }
        })
    }

    activateProducts(productList): void {
        const dialogRef = this.dialog.open(SelectProductComponent, {
            width: '500px',
            panelClass: 'custom-dialog',
            data: {
                products: productList
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.product_choices && result.product_choices.length > 0) {
                result.product_choices.forEach((product) => {
                    this.userService.postActivateProduct(this.eId, this.aToken, product.key).subscribe(succ => {
                        this.toastr.success(`Successfully activated ${product.value}`, '', { disableTimeOut: true });
                    }, 
                    err => {
                        if (err.status === 200) {
                            this.toastr.success(`Successfully activated ${product.value}`, '', { disableTimeOut: true });
                        } else if (err.status === 400) {
                            this.toastr.error(`${err.error}`, '', { disableTimeOut: true });
                        } else {
                            this.toastr.error(`Error activating ${product.value}`, '', { disableTimeOut: true });
                        }
                    });
                });
            }
        });
    }

    freeSNCtrail(): void {
        this.userService.getFreeTrial(this.username, "snc", this.aToken).subscribe(succ => {
        this.toastr.success('Free trial applied.', '', { disableTimeOut: true});
        this.getUserInfo();
        },
        err => {
            this.toastr.error(err.error.message, '', { disableTimeOut: true});
        });

    }

    store(): void {
        window.open("http://store.teledynecaris.com", "_blank");
    }

    logoutuser(): void {
        this._authService.logout();
        this.router.navigateByUrl('/login');
    }

  getUserInfo(): void {

    this.userService.getUserInfo(this.aToken).subscribe(d => {
      
      this.SNCexpiry = d['data']['expiry_date'];
      if (this.SNCexpiry != null) {
        this.SNCexpiry = moment(this.SNCexpiry).toDate()
      }

      this.ukhoGAMExpiry = d['data']['ukhogam_expiry_date'];
      if (this.ukhoGAMExpiry != null) {
        this.ukhoGAMExpiry = moment(this.ukhoGAMExpiry).toDate();
      }

        if (this.SNCexpiry != null && moment(this.SNCexpiry) > moment()) {
            this.SNCexpired = false;
        } else {
            this.SNCexpired = true;
        }

        if (this.ukhoGAMExpiry != null && moment(this.ukhoGAMExpiry) > moment()) {
            this.ukhoGAMExpired = false;
        } else {
            this.ukhoGAMExpired = true;
        }
        });
    }

    goToUsers(): void {
        this.router.navigateByUrl('/manageusers');
    }

    getClassifierServiceStatus(): void {
        this.classifiersService.getServiceStatus().subscribe(
            succ => {
                this.parseServiceStatusMessage(succ['details'])
            },
            err => {
                this.parseServiceStatusMessage(err.error['details'])
            },
        );
        
    }

    parseServiceStatusMessage(msgObj): void {
        if (msgObj) {
            Object.entries(msgObj).forEach(([key, value]) => {
                this.classifiersService.addService(key, value['details']['statusCode']);
            })
            this.SNCStatus = this.classifiersService.getStatus('SINCService');
            this.GNCStatus = this.classifiersService.getStatus('GNCService');
        }
    }
}
