
<div class="main-container">
    <mat-toolbar color="primary">
        <mat-toolbar-row class="modal-toolbar-row">
            <mat-icon>person</mat-icon>
            <h5 mat-dialog-title>Add a new User</h5>
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <form [formGroup]="profileForm" (submit)="onSubmit()" class="form">
        <div mat-dialog-content>
            <mat-form-field appearance="outline" style="width:100%">
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="email" formControlName="email" required>
                <mat-error *ngIf="profileForm.hasError('required','email')">
                    Please enter a valid email
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Company ID</mat-label>
                <input matInput placeholder="company id" formControlName="company_id" required>
                <mat-error *ngIf="profileForm.hasError('required','company_id')">
                    Please enter the Company ID
                </mat-error>
            </mat-form-field>
        </div>
        <div mat-dialog-actions >
            <button [disabled]="!profileForm.valid" class="mira-alternate-button" type="submit">Create User</button>
        </div>
    </form>
</div>