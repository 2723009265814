export default class UrlSources {

    static getAuthorization(): string {
        return './v1/mira/user/authenticate';
    }

    static reset_password(): string {
        return `./v1/mira/user/reset_password`;
    }

    static changePassword(): string {
        return `./v1/mira/user/change_password`;
    }

    static forgetPassword(): string {
        return `./v1/mira/user/forgot_password`;
    }
    
    static confirm_password(): string {
        return `./v1/mira/user/confirm_forgot_password`;
    }

    static get_list_users(): string {
        return `./v1/mira/admin/get_list_users`;
    }

    static create_users(): string {
        return `./v1/mira/admin/create_user`;
    }

    static signup_users(): string {
        return `./v1/mira/admin/signup_user`;
    }

    static delete_user(): string {
        return `./v1/mira/admin/delete_user`;
    }
    
    static enable_user(): string {
        return `./v1/mira/admin/enable_user`;
    }

    static reset_user(): string {
        return `./v1/mira/admin/reset_user`;
    }

    static disable_user(): string {
        return `./v1/mira/admin/disable_user`;
    }

    static get_user_info_admin(): string {
        return `./v1/mira/admin/get_user_info`;
    }

    static get_user_info(): string {
        return `./v1/mira/user/get_user_info`;
    }

    static apply_redeem_code(): string {
        return `./v1/mira/license/apply_redeem`;
    }

    static get_product_items(eId: string): string {
        return `./v1/mira/license/product_items/${eId}`;
    }

    static activate_product(): string {
        return `./v1/mira/license/activate_product`;
    }

    static free_trial(): string {
        return `./v1/mira/license/free_trial`;
    }

   /* static admin_get_user_info(): string {
        return `./v1/admin/admin_get_user_info`;
    }*/

    static update_user_attributes(): string {
        return `./v1/mira/admin/update_user_attributes`;
    }

    static get_business_layer_status(): string {
        return `./v1/mira/status`;
    }

    static get_classifier_status(): string {
        return `./v1/mira/status?recursive`;
    }
}