import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public isAuthenticated = false;

  constructor(private router: Router, private userService: UserService,
    private _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth ) {}

  public ngOnInit(): void {

    this._oktaStateService.authState$.subscribe((state) => {

      this.isAuthenticated = state.isAuthenticated;

      if(state.isAuthenticated) {

        const accessToken = state.accessToken.accessToken;
  
        if (this.userService.isAdmin(accessToken)){
          this.router.navigateByUrl('/manageusers');
        } else{
          this.router.navigateByUrl('/user-page');
        }
      }
    })
  }

  public async oktaLogin() : Promise<void> {
    await this._oktaAuth.signInWithRedirect();
  }

  public newAccount(): void {
    window.open(environment.newAccountUrl, '_blank');
  }

}