
<div class="main-container">
    <mat-toolbar color="primary">
        <mat-toolbar-row class="modal-toolbar-row">
            <mat-icon>person</mat-icon>
            <div mat-dialog-title>Activate Purchase</div>
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="modal-container">
        <div class="text">Enter the activation string from your purchase.</div>
        <form [formGroup]="profileForm" (submit)="onSubmit()" class="form">
            <mat-form-field appearance="outline" class="mira">
                <mat-label>Purchase String</mat-label>
                <input matInput formControlName="eId" required>
                <mat-icon matSuffix class="userButton" title="An activation string is emailed to the purchasing account after checkout from the store.">help_outline</mat-icon>
                <mat-error *ngIf="profileForm.hasError('required','eId')">
                    Please enter your purchase string
                </mat-error>
            </mat-form-field>
            <div mat-dialog-actions>
                <button [disabled]="!profileForm.valid" class="mira-alternate-button" type="submit" style="min-width: 200px;">Activate</button>
            </div>
        </form>
    </div>
</div>