<div>
    <div>
        <mat-toolbar class="mira">
            <button mat-icon-button></button>
            <div class="toolbar">
                <img src="assets/images/teledyne-caris-imaging-logo_white.png" class="responsive">
                <div class="toolbar-icons">
                    <mat-icon class="userButton" title="Profile" (click)='goToProfile()'>person</mat-icon>
                    <mat-icon class="userButton" title="Log Out" (click)='logoutuser()'>exit_to_app</mat-icon>
                </div>
            </div>
        </mat-toolbar>
    </div>
    <div style="padding-left:50px; padding-right:50px;">
        <div style="margin-top:10px; margin-bottom:10px;">
            <div style="color: black;">
                Total users: {{ allUsers.length }}<br>
            </div>
            <div></div>
        </div>
        <div class="users-table-container">
            <table mat-table [dataSource]="allUsers" class="users-table" matSort>
                <!-- matSortActive="username" matSortDisableClear matSortDirection="asc" -->

                <!-- Username Column -->
                <ng-container matColumnDef="username" mat-sort-header>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
                    <td mat-cell *matCellDef="let row">{{row.email}}</td>
                </ng-container>

                <ng-container matColumnDef="expiryDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>SNC License Expiry</th>
                    <td mat-cell *matCellDef="let row">{{ formatExpiration(row.expiry_date) }}</td>
                </ng-container>

                <ng-container matColumnDef="GAMExpiryDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ADMIRALTY GAM License Expiry</th>
                    <td mat-cell *matCellDef="let row">{{ formatExpiration(row.ukhogam_expiry_date) }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <tr mat-cell *matCellDef="let row">
                        <button matTooltip="Edit User" mat-icon-button (click)="editUser(row)">
                            <mat-icon>settings</mat-icon>
                        </button>
                    </tr>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <!-- <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator> -->
    </div>
</div>
