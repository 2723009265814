import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import * as moment from 'moment';

export interface DialogResult {
  username: string;
  expiry: Date;
  GAMExpiry: Date;
}

@Component({
  selector: 'app-edit-users',
  templateUrl: 'edit-users.component.html',
  styleUrls: ['./edit-users.component.css']
})
export class EditUserComponent implements OnInit {
  profileForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<EditUserComponent>, private formBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.profileForm = this.formBuilder.group({
      username: [{ value: this.data.dataKey?.email, disabled: true }],
      expdate: new UntypedFormControl(new Date()),
      GAMExpdate: new UntypedFormControl(new Date()),
    });
  }

  ngOnInit(): void {
    this.profileForm.get('username').setValue(this.data.dataKey?.email)
    this.profileForm.get('expdate').setValue(this.formatExpiration(this.data.dataKey?.expiry_date));
    this.profileForm.get('GAMExpdate').setValue(this.formatExpiration(this.data.dataKey?.ukhogam_expiry_date));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const result: DialogResult = {
      username: this.profileForm.get('username').value,
      expiry: this.profileForm.get('expdate').value,
      GAMExpiry: this.profileForm.get('GAMExpdate').value,
    };
    this.dialogRef.close(
      result
    );
  }

  formatExpiration(expiration: any): Date {
    return expiration != null ? moment(expiration).toDate() : null;
  }
}
