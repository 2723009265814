import { environment } from "../environments/environment";

export default {
    oidc: {
      clientId: environment.clientId,
      issuer: environment.issuer,
      redirectUri: window.location.origin + '/login/callback',
      postLogoutRedirectUri:  window.location.origin + '/login',
      scopes: ['openid', 'mira_user'],
      testing: {
        disableHttpsCheck: true,
      },
    },
  };