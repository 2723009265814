
<div class="main-container">
    <mat-toolbar color="primary">
        <mat-toolbar-row class="modal-toolbar-row">
            <mat-icon>person</mat-icon>
            <h5 mat-dialog-title>Edit User</h5>
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <form [formGroup]="profileForm" (submit)="onSubmit()" class="form">
        <mat-form-field appearance="outline" class="mira">
            <mat-label>E-mail</mat-label>
            <input  matInput placeholder="email" formControlName="username">
        </mat-form-field>
        <mat-form-field appearance="outline" class="mira">
            <input matInput readonly
                matTooltip="Update License Expiry Date"
                [matDatepicker]="picker"
                formControlName="expdate"
                placeholder="Update Licence Expiry Date"
            >
            <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Update License Expiry Date"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mira">
            <input matInput readonly
                matTooltip="Update GAM License Expiry Date"
                [matDatepicker]="GAMPicker"
                formControlName="GAMExpdate"
                placeholder="Update GAM Licence Expiry Date"
            >
            <mat-datepicker-toggle matSuffix [for]="GAMPicker" matTooltip="Update GAM License Expiry Date"></mat-datepicker-toggle>
            <mat-datepicker #GAMPicker></mat-datepicker>
        </mat-form-field>
        <div mat-dialog-actions>
            <button [disabled]="!profileForm.valid" class="mira-alternate-button" type="submit" >Update User</button>
        </div>
    </form>
</div>
