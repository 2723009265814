import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { UserPageComponent } from 'src/user-page/user-page.component';
import {AuthGuard} from 'src/app/authguard/authgaurd'
import { OktaCallbackComponent } from '@okta/okta-angular';

const routes: Routes = [{
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
}, {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
}, {
    path: 'login/callback',
    component: OktaCallbackComponent,
    data: {
      title: 'Login Page'
    }
}, {
    path: 'manageusers',
    component: ManageUsersComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Admin Page'
    }
}, {
    path: 'user-page',
    component: UserPageComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'User Page'
    }
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
