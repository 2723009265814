import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

export interface DialogResult {
    product_choices: string[];
}

@Component({
    selector: 'app-select-product',
    templateUrl: 'select-product.component.html',
    styleUrls: ['./select-product.component.css']
})
  
export class SelectProductComponent {
    productForm: UntypedFormGroup;
    products: any;
    constructor(
        public dialogRef: MatDialogRef<SelectProductComponent>,
        private formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.formatProducts(data);
        this.productForm = this.formBuilder.group({
            choices: new UntypedFormArray([]),
        },);
    }

    formatProducts(data): void {
        if (data && data.products) {
            const keys = Object.keys(data.products);
            const values = Object.values(data.products);
            this.products = keys.map((key, index) => { return {key: keys[`${index}`], value: values[`${index}`]}});
        } else {
            this.products = [];
        }
    }

    onCheck(event): void {
        const formArray: UntypedFormArray = this.productForm.get('choices') as UntypedFormArray;
        if (event.checked) {
            formArray.push(new UntypedFormControl(event.source.value));
        } else {
            formArray.controls.forEach((control: UntypedFormControl, index: number) => {
                if(control.value === event.source.value) {
                    formArray.removeAt(index);
                    return;
                }
            })
        }
    }
    
    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const result: DialogResult = {
            product_choices: this.productForm.get('choices').value
        };
        this.dialogRef.close(
            result
        );
    }

    disableSubmit(): boolean {
        const formArray: UntypedFormArray = this.productForm.get('choices') as UntypedFormArray;
        return formArray.value.length === 0;
    }
}