<mat-toolbar class="mira">
    <button mat-icon-button></button>
    <div class="toolbar">
        <img src="assets/images/teledyne-caris-imaging-logo_white.png" class="responsive">
        <div class="toobar-icons">
            <mat-icon *ngIf="isAdmin" class="userButton" title="Manage Users" (click)='goToUsers()'>people</mat-icon>
            <mat-icon class="userButton" title="Log Out" (click)='logoutuser()'>exit_to_app</mat-icon>
        </div>
    </div>
</mat-toolbar>
<div class="container">
    <div class="user-page-container">
        <div class="center">
            <h1 mat-header>Welcome!</h1>
            <div style="font-size:larger">
                <p>You are now registered on the CARIS Mira AI platform.<br>
                    From this portal you can redeem purchases and view <br> service status.
                </p>
                <p>You can use this account to access AI-based services<br>
                    from CARIS software, simply enter your email and<br>
                    password when prompted.
                </p>
                <p>
                </p>
            </div>
            <div style="font-size:larger">
                <p>
                    After making a purchase, you can enter your purchase<br>
                    activation code below.<br>
                </p>
            </div>
            <div style="text-align:center">
                <button (click)="redeem()" class="mira-alternate-button" style="min-width:250px;">Redeem Purchase</button>
            </div>
        </div>
        <div>
            <mat-card appearance="outlined" class="mira" style="margin-top:20px;">
                <mat-card-header class="mira">
                    <mat-icon mat-card-avatar>person</mat-icon>
                    <mat-card-title>Account Info</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p mat-line>Email: {{username}}</p>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" class="mira" style="margin-top:20px;">
                <mat-card-header class="mira">
                    <mat-icon mat-card-avatar>cloud</mat-icon>
                    <mat-card-title>Sonar Noise Classifier</mat-card-title>
                    <a href="https://www.teledynecaris.com/en/products/caris-sonar-noise-classifier/" target="_blank"><mat-icon mat-card-avatar>help_outline</mat-icon></a>
                </mat-card-header>
                <mat-card-content>
                    <p mat-line *ngIf="SNCStatus">Service status:&nbsp;<span style="color:lightgreen">online</span></p>
                    <p mat-line *ngIf="!SNCStatus">Service status:&nbsp;<span style="color:red">offline</span></p>
                    <p mat-line *ngIf="SNCexpiry != null && SNCexpired">Your access expired&nbsp;<span style="color:red">{{ SNCexpiry | date:"yyyy-MM-dd"}}</span></p>
                    <p mat-line *ngIf="SNCexpiry != null && !SNCexpired">Your access expires&nbsp;<span style="color:lightgreen">{{ SNCexpiry | date:"yyyy-MM-dd"}}</span></p>
                    <p mat-line *ngIf="SNCexpiry == null">No subscription.</p>
                </mat-card-content>
            </mat-card>
    
            <!-- UKHO GAM -->
            <mat-card appearance="outlined" class="mira" style="margin-top:20px;">
                <mat-card-header class="mira">
                    <mat-icon mat-card-avatar>cloud</mat-icon>
                    <mat-card-title>ADMIRALTY GAM</mat-card-title>
                    <a href="https://www.teledynecaris.com/en/products/admiralty-gam/" target="_blank"><mat-icon mat-card-avatar>help_outline</mat-icon></a>
                </mat-card-header>
                <mat-card-content>
                    <p mat-line *ngIf="ukhoGAMExpiry != null && ukhoGAMExpired">Your access expired&nbsp;<span style="color:red">{{ expiry | date:"yyyy-MM-dd"}}</span></p>
                    <p mat-line *ngIf="ukhoGAMExpiry != null && !ukhoGAMExpired">Your access expires&nbsp;<span style="color:lightgreen">{{ ukhoGAMExpiry | date:"yyyy-MM-dd"}}</span></p>
                    <p mat-line *ngIf="ukhoGAMExpiry == null">No subscription.</p>
                </mat-card-content>
            </mat-card>
    
            <mat-card appearance="outlined" class="mira" style="margin-top:20px;">
                <mat-card-header class="mira">
                    <mat-icon mat-card-avatar>cloud</mat-icon>
                    <mat-card-title>Galaxy Noise Classifier</mat-card-title>
                    <a href="https://www.teledyneoptech.com/en/products/airborne-survey/galaxy/" target="_blank"><mat-icon mat-card-avatar>help_outline</mat-icon></a>
                </mat-card-header>
                <mat-card-content>
                    <p mat-line *ngIf="GNCStatus">Service status:&nbsp;<span style="color:lightgreen">online</span></p>
                    <p mat-line *ngIf="!GNCStatus">Service status:&nbsp;<span style="color:red">offline</span></p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
