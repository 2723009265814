import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

export interface DialogResult {
    eId: string;
}

@Component({
    selector: 'app-activate-purchase',
    templateUrl: 'activate-purchase.component.html',
    styleUrls: ['./activate-purchase.component.css']
})
  
export class ActivatePurchaseComponent {
    profileForm: UntypedFormGroup;
    constructor(public dialogRef: MatDialogRef<ActivatePurchaseComponent>, private formBuilder: UntypedFormBuilder) { 
        this.profileForm = this.formBuilder.group({
            eId: ['', [Validators.required]],
        },);
    }
    
    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const result: DialogResult = {
            eId: this.profileForm.get('eId').value,
        };
        this.dialogRef.close(
            result
        );
    }
}